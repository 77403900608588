var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "context-title",
        {
          attrs: {
            title: "Create Section",
            passedActions: _vm.primaryTitleBarActions,
          },
        },
        [
          _c("template", { slot: "secondary" }, [
            _c("div", { staticClass: "row no-gutters align-items-center" }, [
              _c(
                "div",
                { staticClass: "platform-toggle col-12" },
                [
                  _c("toggle", {
                    attrs: { slider: "true", label: "Online" },
                    model: {
                      value: _vm.sectionOnline,
                      callback: function ($$v) {
                        _vm.sectionOnline = $$v
                      },
                      expression: "sectionOnline",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
      _c("LineItem", { staticClass: "row no-gutters" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("FormInput", {
              staticClass: "large block-xs--xs",
              attrs: {
                label: "Section Name",
                spellcheck: "true",
                required: "true",
                labelSize: "partner-form",
              },
              model: {
                value: _vm.sectionName,
                callback: function ($$v) {
                  _vm.sectionName = $$v
                },
                expression: "sectionName",
              },
            }),
          ],
          1
        ),
      ]),
      _c("LineItem", { staticClass: "row no-gutters" }, [
        _c("div", { staticClass: "col-2" }, [
          _c("div", { staticClass: "label block-xs--xs" }, [
            _vm._v("Select Tables By Range:"),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "col-8 add-new",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.addingRange = true
              },
            },
          },
          [
            _c("div", { staticClass: "add-new__img" }),
            _c("div", { staticClass: "add-new__label" }, [
              _vm._v("Add New Range"),
            ]),
          ]
        ),
      ]),
      _c(
        "v-container",
        { staticClass: "pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            {
              staticClass: "range-container",
              staticStyle: { "padding-top": "16px" },
            },
            [
              _vm._l(_vm.ranges, function (range) {
                return _c(
                  "v-col",
                  {
                    key: range.start,
                    staticStyle: {
                      "padding-bottom": "0px",
                      "padding-top": "0px",
                      "margin-bottom": "5px",
                      "margin-top": "0px",
                    },
                    attrs: { md: "12" },
                  },
                  [
                    _c("RangeRow", {
                      attrs: { range: range },
                      on: {
                        change: _vm.rangeValueChanged,
                        delete: _vm.rangeDeleted,
                      },
                    }),
                  ],
                  1
                )
              }),
              _vm.addingRange
                ? _c(
                    "v-col",
                    {
                      staticStyle: {
                        "padding-bottom": "0px",
                        "padding-top": "0px",
                        "margin-bottom": "5px",
                        "margin-top": "16px",
                      },
                      attrs: { md: "12" },
                    },
                    [
                      _c("RangeRow", {
                        attrs: { range: _vm.rangeToAdd },
                        on: { change: _vm.rangeValueChanged },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c("LineItem", { staticClass: "row no-gutters" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "label block-xs--xs" }, [
            _vm._v("Select Tables Individually:"),
          ]),
        ]),
      ]),
      _c(
        "v-container",
        { staticClass: "table-container pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "pa-2 pl-4" },
            [
              _c(
                "v-col",
                {
                  ref: "tableNumbersContainer",
                  style: { height: _vm.remainingHeight },
                  attrs: { md: "12" },
                },
                [
                  _c("RecycleScroller", {
                    staticClass: "scroller",
                    attrs: {
                      items: _vm.tables,
                      itemSize: 48,
                      "key-field": "id",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ item }) {
                          return [
                            _c("MatrixRow", {
                              attrs: { items: item.tables },
                              on: { change: _vm.tableSelectionChanged },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.navigationConfirmationModal
        ? _c(
            "EditingModal",
            { attrs: { size: "sm" } },
            [
              _c(
                "AlertDialog",
                {
                  attrs: {
                    title: "Unsaved Changes",
                    onExit: () => (_vm.navigationConfirmationModal = false),
                    actions: _vm.navigationConfirmationActions,
                  },
                },
                [
                  _vm._v(
                    "You have unsaved changes, would you like to save these changes?"
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.deleteConfirmationModal
        ? _c(
            "EditingModal",
            { attrs: { size: "sm" } },
            [
              _c(
                "AlertDialog",
                {
                  attrs: {
                    title: "Confirm Delete",
                    onExit: () => (_vm.deleteConfirmationModal = false),
                    actions: _vm.deleteConfirmationActions,
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      `Are you sure you want to delete ${_vm.sectionName}?`
                    )
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }