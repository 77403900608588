<template>
  <div>
    <context-title title="Create Section" :passedActions="primaryTitleBarActions">
      <template slot="secondary">
        <div class="row no-gutters align-items-center">
          <div class="platform-toggle col-12">
            <toggle v-model="sectionOnline" slider="true" label="Online" />
          </div>
        </div>
      </template>
    </context-title>
    <LineItem class="row no-gutters">
      <div class="col-12">
        <FormInput v-model="sectionName" class="large block-xs--xs" label="Section Name" spellcheck="true" required="true" labelSize="partner-form" />
      </div>
    </LineItem>
    <LineItem class="row no-gutters">
      <div class="col-2">
        <div class="label block-xs--xs">Select Tables By Range:</div>
      </div>
      <div class="col-8 add-new" @click.stop="addingRange = true">
        <div class="add-new__img" />
        <div class="add-new__label">Add New Range</div>
      </div>
    </LineItem>
    <v-container class="pa-0" fluid>
      <v-row   class="range-container" style="padding-top:16px;">
        <v-col md="12" v-for="range in ranges" :key="range.start" style="padding-bottom: 0px; padding-top: 0px; margin-bottom: 5px; margin-top: 0px;">
          <RangeRow :range="range" @change="rangeValueChanged" @delete="rangeDeleted" />
        </v-col>
        <v-col v-if="addingRange" md="12" style="padding-bottom: 0px; padding-top: 0px; margin-bottom: 5px; margin-top: 16px;">
          <RangeRow :range="rangeToAdd" @change="rangeValueChanged" />
        </v-col>
      </v-row>
    </v-container>
    <LineItem class="row no-gutters">
      <div class="col-12">
        <div class="label block-xs--xs">Select Tables Individually:</div>
      </div>
    </LineItem>
    <v-container fluid class="table-container pa-0">
      <v-row class="pa-2 pl-4"  >
        <v-col md="12" ref="tableNumbersContainer" :style="{height: remainingHeight}">
          <RecycleScroller class="scroller" :items="tables" :itemSize="48" key-field="id">
            <template slot-scope="{item}">
              <MatrixRow :items="item.tables" @change="tableSelectionChanged" />
            </template>
          </RecycleScroller>
        </v-col>
      </v-row>
    </v-container>
    <EditingModal v-if="navigationConfirmationModal" size="sm">
      <AlertDialog title="Unsaved Changes" :onExit="() => navigationConfirmationModal = false" :actions="navigationConfirmationActions">You have unsaved changes, would you like to save these changes?</AlertDialog>
    </EditingModal>
    <EditingModal v-if="deleteConfirmationModal" size="sm">
      <AlertDialog title="Confirm Delete" :onExit="() => deleteConfirmationModal = false" :actions="deleteConfirmationActions">{{ `Are you sure you want to delete ${sectionName}?` }}</AlertDialog>
    </EditingModal>
  </div>
</template>

<script>
import ContextTitle from "components/context_title.vue";
import EditCard from "components/upsell/upsell_group_edit_card.vue";
import ActionButton from "components/action_button";
import TextButton from "components/text_button.vue";
import EditingModal from "components/editing_modal.vue";
import AlertDialog from "components/alert_dialog.vue";
import Toggle from "components/cleverly/Toggle.vue";
import FormInput from "components/cleverly/FormInput.vue";
import LineItem from "components/line_item.vue";
import RangeRow from "components/section_range_row.vue";
import MatrixRow from "components/section_table_matrix.vue";

import { mapActions, mapGetters } from "vuex";
import { createSectionLocationRanges } from "helpers/sections";
import { localToGlobal } from "helpers";
import StoreSettingsMixin from "../mixins/store_settings.js";

import clone from "clone";
import _ from "lodash";
import verge from "verge";

export default {
    name: "StoreSettingsSectionCreate",
    mixins: [StoreSettingsMixin],
    data() {
        return {
            isMounted: false,
            bottomPadding: 0,
            addingRange: false,
            rangeToAdd: {
                start: 0,
                end: 0,
                id: -1,
                display: "New Range"
            },
            dirtySection: undefined,
            sections: [],
            deleteConfirmationModal: false,
            deleteConfirmationActions: [
                {
                    type: "cancel",
                    display: "Cancel",
                    run: () => (this.deleteConfirmationModal = false)
                },
                {
                    type: "remove-red",
                    display: "Yes, delete",
                    run: this.deleteSection
                }
            ],
            pendingRoute: undefined,
            navigationConfirmationModal: false,
            navigationConfirmationActions: [
                {
                    type: "remove-red",
                    display: "No",
                    run: this.confirmNavigation
                },
                {
                    type: "proceed-green",
                    display: "Yes",
                    run: () => {
                        this.navigationConfirmationModal = false;
                    }
                }
            ]
        };
    },
    components: {
        ContextTitle,
        EditCard,
        ActionButton,
        TextButton,
        AlertDialog,
        EditingModal,
        Toggle,
        FormInput,
        LineItem,
        RangeRow,
        MatrixRow
    },
    computed: {
        primaryTitleBarActions() {
            const previous = {
                type: "cancel",
                display: "Cancel",
                run: () => {
                    this.leave();
                }
            };
            if (!this.dirty || !this.validateForm()) return [previous];

            const saveClose = {
                type: "proceed-green",
                display: "Save",
                run: () => {
                    this.save().then(() => this.leave());
                }
            };

            return [previous, saveClose];
        },
        section() {
            return {
                name: "",
                locations: [],
                online: true
            };
        },
        dirty() {
            return this.dirtySection != undefined;
        },
        ranges() {
            var section =
        this.dirtySection == undefined
            ? clone(this.section)
            : clone(this.dirtySection);
            return createSectionLocationRanges(section).locations.filter(
                el =>
                    el.start != undefined &&
          el.end != undefined &&
          el.id != undefined &&
          el.start != el.end
            );
        },
        storeSections() {
            if (this.env == "stage") {
                return this.sectionsStage;
            } else if (this.env == "prod") {
                return this.sectionsProd;
            }
        },
        storeTableNumbers() {
            if (this.env == "stage") {
                return this.tableNumbersStage;
            } else if (this.env == "prod") {
                return this.tableNumbersProd;
            }
        },
        tables() {
            if (this.storeSections == undefined || this.storeTableNumbers == undefined) return []
            let space = "\u2002";
            let section =
        this.dirtySection == undefined ? this.section : this.dirtySection;
            let filteredSections = this.storeSections;
            let invalidLocationIDs = filteredSections
                .flatMap(el => el.locations)
                .map(el => el.locationID);
            var tables = clone(this.storeTableNumbers)
                .filter(el => {
                    return !invalidLocationIDs.includes(el.locationID) && el.bTableOnline;
                })
                .map(el => {
                    return {
                        ...el,
                        label:
              el.locationName.length == 1
                  ? `Table ${el.locationName}${space}`
                  : `Table ${el.locationName}`,
                        selected:
              section.locations.findIndex(
                  a => a.locationName == el.locationName
              ) != -1
                    };
                });
            var arrays = [];
            let size = 6;

            while (tables.length > 0)
                arrays.push({
                    id: arrays.length,
                    tables: tables.splice(0, size)
                });

            return arrays;
        },
        sectionName: {
            get() {
                if (this.dirtySection != undefined) return this.dirtySection.name;
                return this.section.name;
            },
            set(newValue) {
                if (this.dirtySection == undefined) {
                    this.dirtySection = clone(this.section);
                }
                this.dirtySection.name = newValue;
            }
        },
        sectionOnline: {
            get() {
                if (this.dirtySection != undefined) return this.dirtySection.online;
                return this.section.online;
            },
            set(newValue) {
                if (this.dirtySection == undefined) {
                    this.dirtySection = clone(this.section);
                }
                this.dirtySection.online = newValue;
            }
        },
        remainingHeight() {
            if (!this.isMounted || !this.section) return `${verge.viewportH()}px`;

            return `${verge.viewportH() -
        localToGlobal(this.$refs.tableNumbersContainer).top -
        this.bottomPadding}px`;
        },
        ...mapGetters("SettingsStore", [
            "sectionsProd",
            "sectionsStage",
            "tableNumbersStage",
            "tableNumbersProd"
        ])
    },
    methods: {
        labelForTable(table) {
            return `Table ${table.locationName}`;
        },
        isTableSelected(table) {
            let section =
        this.dirtySection == undefined ? this.section : this.dirtySection;
            let index = section.locations.findIndex(
                el => el.locationID == table.locationID
            );
            return index != -1;
        },

        leave() {
            this.guardRouteAway({
                name: `store-settings-sections`
            });
        },

        guardRouteAway(route) {
            if (this.dirtySection === undefined) {
                this.$router.push(route);
                return;
            }

            this.pendingRoute = route;
            this.navigationConfirmationModal = true;
        },
        confirmNavigation() {
            this.navigationConfirmationModal = false;
            this.$router.push(this.pendingRoute);
            this.pendingRoute = undefined;
        },
        save() {
            if (!this.validateForm()) return Promise.Reject();
            let waypointID = this.$route.params.store;
            var payload = this.dirtySection;
            payload.env = this.env
            payload.storeWaypointID = waypointID;
            return this.createSection(payload).then(() => {
                this.dirtySection = undefined;
                return Promise.resolve(true);
            });
        },
        validateForm() {
            if (this.dirtySection === undefined) return false;
            if (this.dirtySection.name == "") {
                //this.toast("Section name is required.", "error");
                return false;
            } else if (this.dirtySection.locations.length == 0) {
                // this.toast("At least 1 table is required.", "error");
                return false;
            }
            return true;
        },
        rangeValueChanged(range) {
            if (range.id == -1) {
                //new range was added
                this.addingRange = false;
            }

            let oldRange = this.ranges.find(el => el.id == range.id);
            if (
                range.id != -1 &&
        range.start == oldRange.start &&
        range.end == oldRange.end
            )
                return;

            if (this.dirtySection == undefined) {
                this.dirtySection = clone(this.section);
            }
            let filteredLocations = this.dirtySection.locations.filter(el => {
                if (_.isNaN(parseInt(el.locationName))) return true;
                let parsed = parseInt(el.locationName);
                if (
                    range.id != -1 &&
          parsed >= oldRange.start &&
          parsed <= oldRange.end
                )
                    return false;
                if (parsed >= range.start && parsed <= range.end) return false;

                return true;
            });
            let newLocations = this.storeTableNumbers.filter(el => {
                if (_.isNaN(parseInt(el.locationName))) return false;
                let parsed = parseInt(el.locationName);
                if (parsed >= range.start && parsed <= range.end) return true;
                return false;
            });

            this.dirtySection.locations = filteredLocations.concat(newLocations);
        },
        tableSelectionChanged(item) {
            if (this.dirtySection == undefined) {
                this.dirtySection = clone(this.section);
            }

            if (item.selected) {
                let index = this.dirtySection.locations.findIndex(
                    el => parseInt(el.locationName) > parseInt(item.locationName)
                );
                if (index == -1) {
                    this.dirtySection.locations = this.dirtySection.locations.concat(
                        item
                    );
                } else {
                    this.dirtySection.locations.splice(index, 0, item);
                }
            } else {
                let index = this.dirtySection.locations.findIndex(
                    el => el.locationID == item.locationID
                );
                if (index == -1) return;
                this.dirtySection.locations.splice(index, 1);
            }
        },
        rangeDeleted(rangeID) {
            let range = this.ranges.find(el => el.id == rangeID);
            if (range == undefined) return;

            if (this.dirtySection == undefined) {
                this.dirtySection = clone(this.section);
            }
            this.dirtySection.locations = this.dirtySection.locations.filter(el => {
                if (_.isNaN(parseInt(el.locationName))) return true;
                let parsed = parseInt(el.locationName);
                if (parsed >= range.start && parsed <= range.end) return false;
                return true;
            });
        },
        ...mapActions("SettingsStore", ["getSections","getTableNumbers", "createSection"])
    },
    mounted() {
        let { store } = this.$route.params;
        this.getSections({
            waypointID: store,
            env: this.env
        }).then(() => {
            this.$nextTick(() => {
                this.isMounted = true;
            });
        });
        this.getTableNumbers({
            waypointID: store,
            env: this.env
        });
    }
};
</script>

<style lang="scss" scoped>
@import "~scss/variables";
@import "~scss/mixins";

input {
  @include below(xs) {
    width: 100%;
  }
}

.form-input {
  input {
    @include above(md) {
      width: 331px;
    }
  }
}

.block-label > label {
  @include roboto-bold;
  display: block;
  font-size: fz(sm);
  color: $grey_mid_1;
  margin: spacing(xs) 0px;
  width: auto;
}

.line-item {
  padding: 0px 30px;
  margin-right: 0;
}

.label-offset {
  margin-left: 155px;
  &.line-item {
    margin-right: -30px;
    &:before {
      display: none;
    }
  }
}
.add-new {
  vertical-align: middle;
  align-items: center;
  display: flex;
  cursor: pointer;

  &__img {
    width: spacing(sm);
    height: spacing(sm);
    background-position: right center;
    background-color: $brand_color;
    @include mask-image("", "plus.svg", "../img/");
    mask-size: 15px 19px;
  }

  &__label {
    height: 21px;
    width: 115px;
    padding-left: 3px;
    color: #72980a;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    line-height: 21px;
  }
}
.range-row {
}

.scroller {
  height: 100%;
  overflow-y: auto;
}
</style>

